import {
    cibNintendoGamecube,
    cilBell,
    cilCalendarCheck,
    cilHospital,
    cilLan,
    cilSpeedometer,
    cilStar,
    cilUser,
    cilViewQuilt,
} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CNavGroup, CNavItem } from '@coreui/react';

import HeraklesIcon from './components/HeraklesIcon';
import AddForms from './views/add-forms/AddForms';
import AnamnesisBuilder from './views/anamnesis-builder/AnamnesisBuilder';
import AppointmentsMapping from './views/appointments-mapping/AppointmentsMapping';
import Appointments from './views/appointments/Appointments';
import Dashboard from './views/dashboard/Dashboard';
import DataAssetsView from './views/data-assets/DataAssets';
import HeraklesForms from './views/herakles/HeraklesForms';
import PatientView from './views/patients/PatientView';
import Patients from './views/patients/Patients';
import PractitionersMapping from './views/practitioners-mapping/PractitionersMapping';
import ProfessionalView from './views/professionals/ProfessionalView';
import Professionals from './views/professionals/Professionals';
import Quicksight from './views/quicksight/Quicksight';
import TodaysAppointments from './views/todaysappointments/TodaysAppointments';
import Users from './views/users/Users';

export const pageKeys = {
    ETERNO_DASHBOARD: 'eterno-dashboard',
    ETERNO_USERS: 'eterno-users',
    ETERNO_PATIENTS: 'eterno-patients',
    ETERNO_PROFESSIONALS: 'eterno-professionals',
    ETERNO_TODAY_APPOINTMENTS: 'eterno-today-appointments',
    ETERNO_FORMS_PORTAL: 'eterno-forms-portal',
    ETERNO_APPOINTMENTS: 'eterno-appointments',
    ETERNO_DC_APPOINTMENT_TYPES: 'eterno-dc-appointment-types',
    ETERNO_ANAMNESIS_BUILDER: 'eterno-anamnesis-builder',
    DATA_ASSETS: 'eterno-data-assets',
    HERAKLES_PATIENTS: 'herakles-patients',
    ETERNO_PRACTITIONERS_MAPPING: 'eterno-practitioners-mapping',
    ETERNO_APPOINTMENTS_MAPPING: 'eterno-appointments-mapping',
    ETERNO_QUICKSIGHT: 'eterno-quicksight',
    ETERNO_ANALYTICS: 'eterno-analytics',
};

export const routes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        key: pageKeys.ETERNO_DASHBOARD,
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        key: pageKeys.ETERNO_USERS,
    },
    {
        path: '/patients',
        name: 'Patients',
        component: Patients,
        key: pageKeys.ETERNO_PATIENTS,
    },
    {
        path: '/herakles-forms',
        name: 'Herakles Forms',
        component: HeraklesForms,
        key: pageKeys.HERAKLES_PATIENTS,
    },
    {
        path: '/professionals',
        name: 'Professionals',
        component: Professionals,
        key: pageKeys.ETERNO_PROFESSIONALS,
    },
    {
        path: '/today-appointments',
        name: 'Heutige Termine',
        component: TodaysAppointments,
        key: pageKeys.ETERNO_TODAY_APPOINTMENTS,
    },
    {
        path: '/forms-portal',
        name: 'Forms Portal',
        component: AddForms,
        key: pageKeys.ETERNO_FORMS_PORTAL,
    },
    {
        path: '/appointments',
        name: 'Appointments',
        component: Appointments,
        key: pageKeys.ETERNO_APPOINTMENTS,
    },
    {
        path: '/anamnesis-builder',
        name: 'Anamnesis Builder',
        component: AnamnesisBuilder,
        key: pageKeys.ETERNO_ANAMNESIS_BUILDER,
    },
    {
        path: '/professional-view',
        name: 'Professional View',
        component: ProfessionalView,
        key: pageKeys.ETERNO_DC_APPOINTMENT_TYPES,
    },
    {
        path: '/patient-view',
        name: 'Patient View',
        component: PatientView,
        key: pageKeys.ETERNO_DC_APPOINTMENT_TYPES,
    },
    {
        path: '/data-assets',
        name: 'Data Assets',
        component: DataAssetsView,
        key: pageKeys.DATA_ASSETS,
    },
    {
        path: '/quicksight',
        name: 'Quicksight',
        component: Quicksight,
        key: pageKeys.ETERNO_QUICKSIGHT,
    },
    {
        path: '/practitioners-mapping',
        name: 'Practitioners Mapping View',
        component: PractitionersMapping,
        key: pageKeys.ETERNO_PRACTITIONERS_MAPPING,
    },
    {
        path: '/appointments-mapping',
        name: 'Appointments Mapping View',
        component: AppointmentsMapping,
        key: pageKeys.ETERNO_APPOINTMENTS_MAPPING,
    },
];

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
export const _nav = [
    {
        component: CNavItem,
        name: 'Heutige Termine',
        to: '/today-appointments',
        icon: <CIcon icon={cilBell} customClassName="nav-icon" />,
        key: pageKeys.ETERNO_TODAY_APPOINTMENTS,
    },
    {
        component: CNavItem,
        name: 'Users',
        to: '/users',
        icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        key: pageKeys.ETERNO_USERS,
    },
    {
        component: CNavItem,
        name: 'Professionals',
        to: '/professionals',
        icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
        key: pageKeys.ETERNO_PROFESSIONALS,
    },
    {
        component: CNavItem,
        name: 'Patients',
        to: '/patients',
        icon: <CIcon icon={cilHospital} customClassName="nav-icon" />,
        key: pageKeys.ETERNO_PATIENTS,
    },
    {
        component: CNavItem,
        name: 'Appointment',
        to: '/appointments',
        icon: <CIcon icon={cilCalendarCheck} customClassName="nav-icon" />,
        key: pageKeys.ETERNO_APPOINTMENTS,
    },
    {
        component: CNavItem,
        name: 'Forms Portal',
        to: '/forms-portal',
        icon: <CIcon icon={cilBell} customClassName="nav-icon" />,
        key: pageKeys.ETERNO_FORMS_PORTAL,
    },

    {
        component: CNavItem,
        name: 'Herakles Forms',
        to: '/herakles-forms',
        icon: <HeraklesIcon />,
        key: pageKeys.HERAKLES_PATIENTS,
    },
    {
        component: CNavGroup,
        name: 'Analytics',
        to: '/analytics',
        icon: <CIcon icon={cilLan} customClassName="nav-icon" />,
        key: pageKeys.ETERNO_ANALYTICS,
        items: [
            {
                component: CNavItem,
                name: 'Dashboard',
                to: '/dashboard',
                icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
            },
            {
                component: CNavItem,
                name: 'Data Assets',
                to: '/data-assets',
                icon: <CIcon icon={cibNintendoGamecube} customClassName="nav-icon" />,
            },
            {
                component: CNavItem,
                name: 'Quicksight',
                to: '/quicksight',
                icon: <CIcon icon={cilViewQuilt} customClassName="nav-icon" />,
            },
        ],
    },
    {
        component: CNavGroup,
        name: 'Mappings',
        to: '/mappings',
        icon: <CIcon icon={cilBell} customClassName="nav-icon" />,
        key: pageKeys.ETERNO_PRACTITIONERS_MAPPING,
        items: [
            {
                component: CNavItem,
                name: 'Practitioners Mapping',
                to: '/practitioners-mapping',
            },
            {
                component: CNavItem,
                name: 'Appointments Mapping',
                to: '/appointments-mapping',
            },
        ],
    },
];
