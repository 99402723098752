import { cilInfo, cilReload } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CSpinner, CTooltip } from '@coreui/react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    getRequiredFormsForAppointment,
    getSelectedFormsForAppointment,
    getSharedForms,
} from '@/api/appointment/AppointmentCalls';

import categoryIllustration from '@/assets/Category_Illustrations.svg';
import fingerprintIcon from '@/assets/Handwriting-cercles.svg';

import AWSContext from '@/context/AWSContext';

import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import usePollingData from '@/hooks/usePollingData';

import { SharedFormStatus, UserStatus } from '@/utils/constants';
import { FeatureKey, InsuranceType, getSharedDocumentsByUserId } from '@/utils/helpers';

import { useAdminConfigStore, useLocationStore, usePrismicStore, useTodaysAppointmentsStore } from '@/zustandStore';

import DetailsField from '../DetailsField';
import EternoSpinner from '../EternoLogoSpinner/EternoSpinner';
import Icon from '../Icon/Icon';
import { AppointmentDetailsDocuments } from './AppointmentDetailsDocuments';
import { AppointmentStatus } from './AppointmentStatusTag';
import BasicStatusTag, { BasicStatus } from './BasicStatusTag';
import CheckinCard from './CheckinCard';
import EternoUserTag from './EternoUserTag';

const fetcher = async ([userToken, userId]) => getSharedForms(userToken, userId);

const AppointmentDetails = ({
    appointment,
    detailsLoading,
    setDetailsLoading,
    patientName,
    professionalName,
    showNewCheckin,
    showLegacyCheckin,
    checkin,
    updateCheckin,
    confirmCheckin,
    startLegacyCheckin,
}) => {
    const { userToken } = useContext(AWSContext);
    const { t } = useTranslation();
    const [sharedForms, setSharedForms] = useState({});
    const featureAccess = useAdminConfigStore((state) => state.featureAccess);
    const checkinData = useTodaysAppointmentsStore((state) => state.checkinData);
    const anamnesisData = useTodaysAppointmentsStore((state) => state.anamnesisData);
    const professionalFormTemplates = useTodaysAppointmentsStore((state) => state.professionalFormTemplates);
    const selectedForms = useTodaysAppointmentsStore((state) => state.selectedForms);
    const requiredForms = useTodaysAppointmentsStore((state) => state.requiredForms);
    const selectedLocation = useLocationStore((state) => state.selectedLocation);
    const appointmentDetailsRef = useRef();
    const medicalRecords = usePrismicStore((state) => state.medicalRecords);
    const isDocumentExchangeFeatureFlagActivated = useFeatureFlag('document_exchange');

    const hasPendingData = (data) => data.every((item) => item.context.status !== SharedFormStatus.PENDING);

    const { data } = usePollingData(
        [
            userToken,
            appointment.user_id && appointment.user_id !== '-' ? appointment.user_id : appointment.data?.generated_id,
        ],
        fetcher,
        hasPendingData
    );

    useEffect(() => {
        if (!data) return;

        const sharedFormsObj = data.reduce((obj, form) => {
            // eslint-disable-next-line no-param-reassign
            obj[form.context.template_id] = form;
            return obj;
        }, {});

        setSharedForms(sharedFormsObj);
    }, [data]);

    const scrollToTop = () => {
        appointmentDetailsRef.current?.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const loadPreselectedForms = async () => {
        const userId =
            appointment.user_id && appointment.user_id !== '-' ? appointment.user_id : appointment.data?.generated_id;

        if (!userId || userId === '-') return;

        let requiredFormsResponse = await getRequiredFormsForAppointment(
            userToken,
            userId,
            appointment.professional.id,
            appointment.data.insurance_coverage_type.toLowerCase() === 'selfpayer'
                ? 'SELF_PAYER'
                : appointment.data?.insurance_coverage_type.toUpperCase(),
            appointment.data.services.map((service) => service.key).join()
        );

        if (!requiredFormsResponse || requiredFormsResponse.length === 0) return;

        requiredFormsResponse = requiredFormsResponse.filter(
            (form) => professionalFormTemplates[appointment.professional.key][form.id]
        );

        if (!featureAccess[FeatureKey.CUSTOM_ANAMNESIS] || !selectedLocation.config.is_custom_anamnesis_activated) {
            requiredFormsResponse = requiredFormsResponse.filter(
                (form) => !professionalFormTemplates[appointment.professional.key][form.id].is_custom
            );
        }

        let preselectedForms = await getSelectedFormsForAppointment(userToken, userId, appointment.id);
        if (preselectedForms && Array.isArray(preselectedForms)) {
            preselectedForms = preselectedForms.filter(
                (form) => professionalFormTemplates[appointment.professional.key][form.id]
            );

            if (!featureAccess[FeatureKey.CUSTOM_ANAMNESIS] || !selectedLocation.config.is_custom_anamnesis_activated) {
                preselectedForms = preselectedForms.filter(
                    (formId) =>
                        professionalFormTemplates[appointment.professional.key][formId] &&
                        !professionalFormTemplates[appointment.professional.key][formId].is_custom
                );
            }

            useTodaysAppointmentsStore.setState({
                selectedForms: { ...selectedForms, [appointment.id]: preselectedForms },
                requiredForms: { ...requiredForms, [appointment.id]: requiredFormsResponse },
            });
            return;
        }

        useTodaysAppointmentsStore.setState({
            selectedForms: {
                ...selectedForms,
                [appointment.id]: requiredFormsResponse.filter((form) => !form.is_completed),
            },
            requiredForms: { ...requiredForms, [appointment.id]: requiredFormsResponse },
        });
    };

    const checkAnamnesisStatus = async () => {
        if (
            appointment.is_external ||
            anamnesisData[appointment.user_id] ||
            appointment.user_status === UserStatus.UNKNOWN
        ) {
            return;
        }

        const sharedDocuments = await getSharedDocumentsByUserId(userToken, appointment.user_id);

        if (!sharedDocuments) {
            return;
        }

        const hasSharedWithProfessional = sharedDocuments.some(
            (document) => document.type === 'ANAMNESIS' && document.name.includes(professionalName.replaceAll(' ', '_'))
        );

        if (hasSharedWithProfessional) {
            const newAnamnesisData = { ...anamnesisData };
            newAnamnesisData[appointment.user_id] = true;
            useTodaysAppointmentsStore.setState({ anamnesisData: newAnamnesisData });
        }
    };

    const loadAppointmentAndPatientInfo = async () => {
        await Promise.all([checkAnamnesisStatus(), loadPreselectedForms()]);
        setDetailsLoading(false);
    };

    const resetCheckin = async () => {
        setDetailsLoading(true);
        await updateCheckin(
            AppointmentStatus.PENDING,
            checkinData[appointment.id].number ??
                useTodaysAppointmentsStore.getState().appointmentTicketNumber[appointment.id],
            checkinData[appointment.id].appointment.doctor_name
        );
        setDetailsLoading(false);
    };

    const recheckForms = async () => {
        setDetailsLoading(true);
        await checkAnamnesisStatus();
        setDetailsLoading(false);
    };

    useEffect(() => {
        loadAppointmentAndPatientInfo();
    }, [appointment]);

    const appointmentInsuranceCardStatus = useMemo(() => {
        if (
            appointment.user_status === UserStatus.UNKNOWN ||
            appointment.data?.insurance_coverage_type.toUpperCase() !== InsuranceType.PUBLIC
        ) {
            return BasicStatus.NOT_RELEVANT;
        }

        if (appointment.data?.is_insurance_card_active) return BasicStatus.EGK_READ;

        return BasicStatus.EGK_NOT_READ;
    }, [appointment]);

    const insuranceCardStatusTooltip = useMemo(() => {
        if (
            appointment.user_status === UserStatus.UNKNOWN ||
            appointment.data?.insurance_coverage_type.toUpperCase() !== InsuranceType.PUBLIC
        ) {
            return 'Nicht relevant';
        }

        if (appointment.data?.is_insurance_card_active) return 'eGK in diesem Quartal bereits eingelesen';

        return 'eGK in diesem Quartal noch nicht eingelesen';
    }, [appointment]);

    const anamnesisStatus = useMemo(() => {
        if (appointment.is_external || appointment.user_status === UserStatus.UNKNOWN) {
            return BasicStatus.UNKNOWN;
        }

        if (anamnesisData[appointment.user_id]) return BasicStatus.YES;

        return BasicStatus.NO;
    }, []);

    const getFormStatusComponent = (formId) => {
        const sharedStatus = sharedForms?.[formId]?.context?.status;

        if (!sharedStatus) return <BasicStatusTag status={BasicStatus.NO} />;

        if (sharedStatus === SharedFormStatus.PENDING) return <CSpinner />;

        return (
            <BasicStatusTag
                status={sharedStatus === SharedFormStatus.COMPLETED ? BasicStatus.YES : BasicStatus.UNKNOWN}
            />
        );
    };

    if (detailsLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    background: 'white',
                    width: '40%',
                    position: 'relative',
                    height: '80vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {detailsLoading ? <EternoSpinner /> : <h4>{t('FailedCallTitle')}</h4>}
            </div>
        );
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                background: 'white',
                width: '40%',
                padding: '2rem',
                overflow: 'scroll',
            }}
            ref={appointmentDetailsRef}
        >
            {appointment.status !== 'CANCELLED' &&
                appointment.professional?.key &&
                appointment.user_status !== UserStatus.UNKNOWN &&
                checkinData[appointment.id]?.appointment?.checkin_status &&
                checkinData[appointment.id]?.appointment?.checkin_status !== AppointmentStatus.ARRIVED && (
                    <h5>{`${
                        appointment.data.patient_name ||
                        (patientName?.first && `${patientName.first} ${patientName.last}`) ||
                        checkinData[appointment.id]?.name ||
                        t('Patient')
                    } ${t(
                        checkinData[appointment.id]?.appointment?.checkin_status === AppointmentStatus.CHECKED_IN ||
                            checkinData[appointment.id]?.appointment?.checkin_status ===
                                AppointmentStatus.MANUALLY_CHECKED_IN
                            ? 'PatientIsCheckedIn'
                            : 'PatientCanBeCheckedIn'
                    )}`}</h5>
                )}
            {appointment.user_status === UserStatus.UNKNOWN &&
                checkinData[appointment.id]?.appointment?.checkin_status === AppointmentStatus.MANUALLY_CHECKED_IN && (
                    <h5>{`${appointment.name} ${t('PatientIsCheckedIn')}`}</h5>
                )}
            {checkinData[appointment.id]?.appointment?.checkin_status === AppointmentStatus.ARRIVED && (
                <h5>{`${
                    appointment.data?.patient_name ||
                    (patientName?.first && `${patientName.first} ${patientName.last}`) ||
                    checkinData[appointment.id]?.name ||
                    t('Patient')
                } füllt Formulare aus`}</h5>
            )}
            <CheckinCard
                showNewCheckin={showNewCheckin}
                showLegacyCheckin={showLegacyCheckin}
                appointment={appointment}
                patientName={patientName}
                status={
                    checkinData[appointment.id]
                        ? checkinData[appointment.id].appointment?.checkin_status
                        : AppointmentStatus.PENDING
                }
                checkin={checkin}
                resetCheckin={resetCheckin}
                confirmCheckin={confirmCheckin}
                scrollToTop={scrollToTop}
                startLegacyCheckin={startLegacyCheckin}
            />
            <div style={{ borderBottom: '2px solid #f5f5f5', position: 'relative' }}>
                <h6
                    style={{
                        textTransform: 'uppercase',
                        color: '#ABABAB',
                        fontWeight: '600',
                        margin: '1rem 0',
                        fontSize: '0.75rem',
                    }}
                >
                    Patienten Details
                </h6>
                <div className="d-flex flex-column gap-2 mb-3">
                    <DetailsField name={t('Patient')}>
                        <p className="m-0 p-0">
                            <EternoUserTag
                                isEternoUser={
                                    !appointment.is_external && appointment.user_status !== UserStatus.UNKNOWN
                                }
                            />
                        </p>
                    </DetailsField>
                    <DetailsField name={t('Insurance')}>
                        <p className="m-0 p-0">
                            {(appointment.data?.insurance_coverage_type &&
                                t(appointment.data.insurance_coverage_type.toLowerCase())) ||
                                '-'}
                        </p>
                    </DetailsField>
                    <DetailsField name="eGK-Status">
                        <BasicStatusTag status={appointmentInsuranceCardStatus} />

                        <CTooltip content={insuranceCardStatusTooltip}>
                            <CIcon className="mx-3" style={{ cursor: 'pointer' }} icon={cilInfo} size="sm" />
                        </CTooltip>
                    </DetailsField>
                    {(!featureAccess[FeatureKey.CUSTOM_ANAMNESIS] ||
                        !selectedLocation.config.is_custom_anamnesis_activated ||
                        (appointment.professional?.key &&
                            !Object.values(professionalFormTemplates[appointment.professional.key]).some(
                                (template) => template.is_custom
                            ))) && (
                        <DetailsField name={t('Anamnesis')}>
                            <div className="m-0 p-0 d-flex">
                                <BasicStatusTag status={anamnesisStatus} />
                            </div>
                            {!(appointment.is_external || appointment.user_status === UserStatus.UNKNOWN) &&
                                !anamnesisData[appointment.user_id] && (
                                    <div style={{ cursor: 'pointer', marginLeft: '0.75rem' }} onClick={recheckForms}>
                                        <CIcon icon={cilReload} />
                                    </div>
                                )}
                        </DetailsField>
                    )}
                    {featureAccess[FeatureKey.CONSENT_FORMS] &&
                        requiredForms[appointment.id]?.map((form) => (
                            <DetailsField
                                key={form.id}
                                name={
                                    professionalFormTemplates[
                                        appointment.professional?.key ??
                                            checkinData[appointment.id].appointment.doctor_name
                                    ][form.id]?.is_custom
                                        ? professionalFormTemplates[
                                              appointment.professional?.key ??
                                                  checkinData[appointment.id].appointment.doctor_name
                                          ][form.id]?.form_name_de
                                        : professionalFormTemplates[
                                              appointment.professional?.key ??
                                                  checkinData[appointment.id].appointment.doctor_name
                                          ][form.id]?.content.name
                                }
                            >
                                <p className="m-0 p-0">{getFormStatusComponent(form.id)}</p>
                            </DetailsField>
                        ))}
                </div>
                <div
                    style={{
                        width: '2.5rem',
                        height: '3.5rem',
                        position: 'absolute',
                        right: '2rem',
                        top: '50%',
                        transform: 'translate(0, -50%)',
                    }}
                >
                    <Icon src={fingerprintIcon} width="100%" height="100%" />
                </div>
            </div>
            <div style={{ borderBottom: '2px solid #f5f5f5', position: 'relative' }}>
                <h6
                    style={{
                        textTransform: 'uppercase',
                        color: '#ABABAB',
                        fontWeight: '600',
                        margin: '1rem 0',
                        fontSize: '0.75rem',
                    }}
                >
                    Termin Details
                </h6>
                <div className="d-flex flex-column gap-2">
                    <DetailsField name={t('Professional')}>
                        <p className="m-0 p-0">{professionalName || '-'}</p>
                    </DetailsField>
                    <DetailsField name={t('Appointment Type')} alignStart>
                        <p className="m-0 p-0" style={{ maxWidth: '12rem', wordWrap: 'break-word' }}>
                            {!appointment.is_external ? appointment.name || '-' : appointment.name.split(', ').pop()}
                        </p>
                    </DetailsField>
                    <DetailsField name={`${t('Appointment')} ${t('Time')}`}>
                        <p className="m-0 p-0">
                            {(appointment.start &&
                                new Date(appointment.start).toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                })) ||
                                (appointment.appointment.checkin_time &&
                                    new Date(appointment.appointment.checkin_time).toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })) ||
                                '-'}
                        </p>
                    </DetailsField>
                    <DetailsField name={t('Duration')}>
                        <p className="m-0 p-0">
                            {(appointment.duration &&
                                `${appointment.duration.value} ${t(appointment.duration.units.toLowerCase())}`) ||
                                '-'}
                        </p>
                    </DetailsField>
                </div>
                <div
                    style={{
                        width: '3rem',
                        height: '3.5rem',
                        position: 'absolute',
                        right: '2rem',
                        top: '50%',
                        transform: 'translate(0, -50%)',
                    }}
                >
                    <Icon src={categoryIllustration} width="100%" height="100%" />
                </div>
            </div>
            {isDocumentExchangeFeatureFlagActivated && (
                <div style={{ position: 'relative' }}>
                    <h6
                        style={{
                            textTransform: 'uppercase',
                            color: '#ABABAB',
                            fontWeight: '600',
                            margin: '1rem 0',
                            fontSize: '0.75rem',
                        }}
                    >
                        {medicalRecords?.download_cta[0]?.text}
                    </h6>
                    <AppointmentDetailsDocuments appointment={appointment} />
                </div>
            )}
        </div>
    );
};

export default AppointmentDetails;
