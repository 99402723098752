import { cilReload, cilUserPlus } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CContainer, CForm, CFormInput, CFormSwitch } from '@coreui/react';
import { Tab, Tabs } from '@mui/material';
import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import useSWRImmutable from 'swr/immutable';

import AccessRequest from '@/components/Users/AccessRequest';
import CreateUserPopup from '@/components/Users/CreateUserPopup';
import EditUserPopup from '@/components/Users/EditUserPopup';
import UserEntry from '@/components/Users/UserEntry';

import useAuth from '@/hooks/useAuth';

import { AccessRequestStatus, AccessRequestTabs } from '@/utils/constants';
import { FeatureKey, getAccessRequests, getAdminUsers, getAllGroups, getPrismicText } from '@/utils/helpers';

import { useAdminConfigStore, useAdminUsersStore, usePrismicStore } from '@/zustandStore';

import LoadingPage from '../pages/loading/LoadingPage';

const Users = () => {
    const { t } = useTranslation(['common']);
    const { userToken, user: authUser } = useAuth();
    const users = useAdminUsersStore((state) => state.users);
    const featureAccess = useAdminConfigStore((state) => state.featureAccess);
    const activeAccessRequests = useAdminUsersStore((state) => state.activeAccessRequests);
    const inactiveAccessRequests = useAdminUsersStore((state) => state.inactiveAccessRequests);
    const userScopes = usePrismicStore((state) => state.userScopes);
    const customers = usePrismicStore((state) => state.allCustomers);
    const locationHubs = usePrismicStore((state) => state.locationHubs);
    const prismicData = usePrismicStore((state) => state.accessRequests);
    const [allUserGroups, setAllUserGroups] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [showEternoUsers, setShowEternoUsers] = useState(true);
    const [selectedUsers, setSelectedUsers] = useState({});
    const [showCreateUserPopup, setShowCreateUserPopup] = useState(false);
    const [showEditUserPopup, setShowEditUserPopup] = useState(false);
    const [currentTab, setCurrentTab] = useState(null);
    const [currentAccessRequestTab, setCurrentAccessRequestTab] = useState(AccessRequestTabs.ACTIVE);

    const isAdminUser = useMemo(() => authUser.groups?.includes('admin'), [authUser]);

    // TODO refactor admin access
    const tabAccess = useMemo(
        () => ({
            [FeatureKey.USER_MANAGEMENT]:
                featureAccess[FeatureKey.USER_MANAGEMENT] || isAdminUser ? FeatureKey.USER_MANAGEMENT : null,
            [FeatureKey.ACCESS_REQUESTS]:
                featureAccess[FeatureKey.ACCESS_REQUESTS] || isAdminUser ? FeatureKey.ACCESS_REQUESTS : null,
        }),
        [featureAccess, isAdminUser]
    );

    const requestTabMap = useMemo(
        () => ({
            [AccessRequestTabs.ACTIVE]: activeAccessRequests,
            [AccessRequestTabs.HISTORY]: inactiveAccessRequests,
        }),
        [activeAccessRequests, inactiveAccessRequests]
    );

    const customersById = useMemo(
        () =>
            Object.values(customers).reduce((acc, customer) => {
                acc[customer.customer_id[0].text] = customer;
                return acc;
            }, {}),
        [customers]
    );

    const locationsByGroup = useMemo(
        () =>
            locationHubs?.reduce(
                (acc, hub) => ({
                    ...acc,
                    [hub.data.group_key[0].text]: hub.data,
                }),
                {}
            ) ?? [],
        [locationHubs]
    );

    const userEntryRefs = useMemo(
        () =>
            filteredUsers.reduce((acc, userId) => {
                acc[userId] = createRef();
                return acc;
            }, {}),
        [filteredUsers]
    );

    const scrollToUpdatedUser = (userId) => {
        userEntryRefs[userId]?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    };

    const checkForAcceptedRequest = (userId, newInactiveAccessRequests) => {
        const oldRequest = activeAccessRequests.find((request) => request.user_id === userId);
        if (!oldRequest || oldRequest.status !== AccessRequestStatus.PENDING) return;

        const newRequest = newInactiveAccessRequests.find((request) => request.user_id === userId);
        if (!newRequest || newRequest.status !== AccessRequestStatus.ACCEPTED) return;

        scrollToUpdatedUser(userId);
    };

    const getUsers = useCallback(async () => {
        const data = await getAdminUsers(userToken);
        if (data) {
            const usersObj = data.reduce((obj, user) => {
                // eslint-disable-next-line no-param-reassign
                obj[user.id] = user;

                return obj;
            }, {});
            useAdminUsersStore.setState({ users: usersObj });
            setSearch('');
        }
    }, [userToken, search]);

    const fetchAllGroups = async () => {
        const { data } = await getAllGroups(userToken);
        if (!data) {
            setAllUserGroups({});
            return;
        }

        const result = data
            .filter(
                (group) => !group.name.toLowerCase().includes('google') && !group.name.toLowerCase().includes('admin')
            )
            .reduce((accumulator, currentValue) => {
                accumulator[currentValue.name] = true;
                return accumulator;
            }, {});
        setAllUserGroups(result);
    };

    const loadAccessRequests = useCallback(
        async (updatedUserId = '') => {
            const response = await getAccessRequests(userToken);
            if (!response) {
                toast.error('Failed to load access requests');
                useAdminUsersStore.setState({
                    activeAccessRequests: [],
                    inactiveAccessRequests: [],
                });
                return;
            }

            const transformedRequests = response.map((request) => ({
                ...request,
                decisions: request.decisions.reduce((accumulator, decision) => {
                    accumulator[decision.user_id] = decision;
                    return accumulator;
                }, {}),
            }));

            const newActiveAccessRequests = [];
            const newInactiveAccessRequests = [];
            transformedRequests.forEach((request) => {
                if (request.status === AccessRequestStatus.PENDING) newActiveAccessRequests.push(request);
                else newInactiveAccessRequests.push(request);
            });

            newActiveAccessRequests.sort((a, b) => new Date(b.meta.updated_at) - new Date(a.meta.updated_at));
            newInactiveAccessRequests.sort((a, b) => new Date(b.meta.updated_at) - new Date(a.meta.updated_at));

            if (updatedUserId) checkForAcceptedRequest(updatedUserId, newInactiveAccessRequests);

            useAdminUsersStore.setState({
                activeAccessRequests: newActiveAccessRequests,
                inactiveAccessRequests: newInactiveAccessRequests,
            });
        },
        [userToken, activeAccessRequests, inactiveAccessRequests, userEntryRefs]
    );

    const loadData = async () => {
        await Promise.all([fetchAllGroups(), getUsers(), loadAccessRequests()]);
        setLoading(false);
    };

    useSWRImmutable('admin-users', loadData);

    const reloadData = async () => {
        setLoading(true);
        await loadData();
    };

    const applyFilters = () => {
        const includesGoogle = (user) => {
            return user.groups?.some((group) => group.toLowerCase().includes('google'));
        };
        if (showEternoUsers) {
            return Object.values(users).filter((user) => includesGoogle(user));
        }
        return Object.values(users).filter((user) => !includesGoogle(user));
    };

    const updateSearch = () => {
        const newFilteredUsers = applyFilters();
        if (!search) {
            setFilteredUsers(newFilteredUsers.map((user) => user.id));
            return;
        }

        setFilteredUsers(
            newFilteredUsers
                .filter(
                    (user) =>
                        user.email.toLowerCase().includes(search.toLowerCase()) ||
                        user.username.toLowerCase().includes(search.toLowerCase())
                )
                .map((user) => user.id)
        );
    };

    useEffect(() => {
        updateSearch();
    }, [search, users, showEternoUsers]);

    const filteredAccessRequests = useMemo(() => {
        if (!search) return requestTabMap[currentAccessRequestTab] ?? [];

        return (
            requestTabMap[currentAccessRequestTab]?.filter((request) =>
                request.name.toLowerCase().trim().includes(search.toLowerCase().trim())
            ) ?? []
        );
    }, [requestTabMap, currentAccessRequestTab, search]);

    const tabNames = useMemo(() => {
        if (!prismicData) return null;

        return {
            [AccessRequestTabs.ACTIVE]: getPrismicText(prismicData.active_tab),
            [AccessRequestTabs.HISTORY]: getPrismicText(prismicData.history_tab),
        };
    }, [prismicData]);

    const setUpdatingUserStatus = (userId, isCurrentlyUpdating) => {
        useAdminUsersStore.setState({
            currentlyUpdatingUsers: {
                ...useAdminUsersStore.getState().currentlyUpdatingUsers,
                [userId]: isCurrentlyUpdating,
            },
        });
    };

    const renderedTab = useMemo(() => {
        const isUserTabShown =
            currentTab === FeatureKey.USER_MANAGEMENT || (!currentTab && tabAccess[FeatureKey.USER_MANAGEMENT]);

        if (isUserTabShown) {
            return (
                <CContainer style={{ marginTop: 20, marginBottom: 10 }}>
                    <div className="d-flex align-items-center justify-content-between">
                        <CForm className="d-flex align-items-center gap-4 w-50">
                            <CFormInput
                                className="w-25"
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder={`${t('Search')}`}
                            />
                            <CFormSwitch
                                label={t('Eterno User')}
                                checked={showEternoUsers}
                                onChange={() => setShowEternoUsers(!showEternoUsers)}
                            />
                        </CForm>

                        <div className="w-50 d-flex justify-content-end gap-3">
                            {Object.keys(selectedUsers).length > 0 && (
                                <CButton
                                    color="secondary"
                                    className="px-3 d-flex gap-2 align-items-center"
                                    onClick={() => {
                                        setShowEditUserPopup(true);
                                    }}
                                >
                                    Edit User(s)
                                </CButton>
                            )}
                            <CButton
                                color="secondary"
                                className="px-3 d-flex gap-2 align-items-center"
                                onClick={() => {
                                    setShowCreateUserPopup(true);
                                }}
                            >
                                {t('CreateUsers')}
                                <CIcon icon={cilUserPlus} />
                            </CButton>
                            <CButton
                                color="secondary"
                                className="px-3 d-flex gap-2 align-items-center"
                                onClick={reloadData}
                            >
                                {t('Reload')}
                                <CIcon icon={cilReload} />
                            </CButton>
                        </div>
                    </div>
                    <div className="mt-4 d-flex flex-column gap-3">
                        {filteredUsers.map((userId) => (
                            <UserEntry
                                key={userId}
                                ref={userEntryRefs[userId]}
                                user={users[userId]}
                                selectedUsers={selectedUsers}
                                setSelectedUsers={setSelectedUsers}
                                setShowEditUserPopup={setShowEditUserPopup}
                            />
                        ))}
                    </div>
                </CContainer>
            );
        }

        if (currentTab === FeatureKey.ACCESS_REQUESTS || tabAccess[FeatureKey.ACCESS_REQUESTS]) {
            return (
                <CContainer style={{ marginTop: 20, marginBottom: 10 }}>
                    <h2 className="m-0 p-0 mb-3">{getPrismicText(prismicData.access_requests_cta)}</h2>
                    <Tabs
                        value={currentAccessRequestTab}
                        onChange={(event, newValue) => setCurrentAccessRequestTab(newValue)}
                        TabIndicatorProps={{
                            style: {
                                background: '#4C726D',
                            },
                        }}
                    >
                        {Object.values(AccessRequestTabs).map((arTab) => (
                            <Tab
                                key={arTab}
                                disableRipple
                                value={arTab}
                                label={`${tabNames[arTab]} (${requestTabMap[arTab]?.length ?? 0})`}
                                style={{ color: currentAccessRequestTab === arTab ? '#000' : '#a9a9a9' }}
                            />
                        ))}
                    </Tabs>
                    <div className="d-flex flex-column gap-3">
                        {filteredAccessRequests.map((request) => (
                            <AccessRequest
                                key={request.user_id}
                                data={request}
                                customersById={customersById}
                                locationsByGroup={locationsByGroup}
                                loadAccessRequests={loadAccessRequests}
                                getUsers={getUsers}
                            />
                        ))}
                    </div>
                    {filteredAccessRequests.length === 0 && (
                        <div
                            className="w-100 d-flex justify-content-center align-items-center"
                            style={{ height: '4rem' }}
                        >
                            <p className="m-0 p-0">
                                {currentAccessRequestTab === AccessRequestTabs.ACTIVE
                                    ? getPrismicText(prismicData.no_active_requests)
                                    : getPrismicText(prismicData.no_inactive_requests)}
                            </p>
                        </div>
                    )}
                </CContainer>
            );
        }

        return null;
    }, [
        currentTab,
        tabAccess,
        search,
        showEternoUsers,
        selectedUsers,
        filteredUsers,
        userEntryRefs,
        users,
        prismicData,
        currentAccessRequestTab,
        tabNames,
        requestTabMap,
        filteredAccessRequests,
        customersById,
        locationsByGroup,
    ]);

    if (loading || !prismicData) return <LoadingPage />;

    return (
        <>
            <div className="mb-5">
                {(tabAccess[FeatureKey.USER_MANAGEMENT] || tabAccess[FeatureKey.ACCESS_REQUESTS]) && (
                    <Tabs
                        value={
                            currentTab ??
                            tabAccess[FeatureKey.USER_MANAGEMENT] ??
                            tabAccess[FeatureKey.ACCESS_REQUESTS] ??
                            null
                        }
                        onChange={(event, newValue) => setCurrentTab(newValue)}
                        TabIndicatorProps={{
                            style: {
                                background: '#4C726D',
                            },
                        }}
                    >
                        {tabAccess[FeatureKey.USER_MANAGEMENT] && (
                            <Tab
                                disableRipple
                                value={tabAccess[FeatureKey.USER_MANAGEMENT]}
                                label="Users"
                                style={{
                                    color: currentTab === tabAccess[FeatureKey.USER_MANAGEMENT] ? '#000' : '#a9a9a9',
                                }}
                            />
                        )}
                        {tabAccess[FeatureKey.ACCESS_REQUESTS] && (
                            <Tab
                                disableRipple
                                value={tabAccess[FeatureKey.ACCESS_REQUESTS]}
                                label={`${getPrismicText(prismicData.access_requests_cta)} ${
                                    activeAccessRequests.length > 0 ? `(${activeAccessRequests.length})` : ''
                                }`}
                                style={{
                                    color: currentTab === tabAccess[FeatureKey.ACCESS_REQUESTS] ? '#000' : '#a9a9a9',
                                }}
                            />
                        )}
                    </Tabs>
                )}
                {renderedTab}
            </div>
            <CreateUserPopup
                visible={showCreateUserPopup}
                setVisible={setShowCreateUserPopup}
                reloadUsers={reloadData}
            />
            <EditUserPopup
                visible={showEditUserPopup}
                setVisible={setShowEditUserPopup}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                userGroups={allUserGroups}
                userScopes={userScopes}
                setUpdatingUserStatus={setUpdatingUserStatus}
                customers={customers}
            />
        </>
    );
};

export default Users;
